import { getInvoiceDetails, getInvoices } from '@/lib/adapters/invoice-adapter';
import { getInvoicePaymentsByInvoiceId } from '@/lib/adapters/payment-adapter';
import { applicationSettingsAtom } from '@/lib/atoms/atoms';
import { InvoiceType, OnSuccessHandlers, SortOrder } from '@/lib/types';
import { useQuery } from '@tanstack/react-query';
import { Affix, Card, Col, Row } from 'antd';
import { useAtomValue } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceDetail } from '../../lib/components/invoice-detail/invoiceDetail';
import { FilterOptions } from '../../lib/types/invoices';
import { convertToExtendedInvoice } from '../../lib/utils/extendedInvoice';
import { DossierFilter } from './_components/dossierFilter';
import { DossierHeader } from './_components/dossierHeader';
import { DossierList } from './_components/dossierList';
import styles from './styles.module.scss';
import { useGetIdentifierTypes } from '@/lib/queries';

const INVOICE_TYPES = {
  all: undefined,
  purchase: [InvoiceType.Purchase, InvoiceType.CreditNote],
  sales: [InvoiceType.Sales, InvoiceType.SalesCreditNote],
};

export const Dossier: FC = () => {
  const [invoiceZipFileIds, setInvoiceZipFiles] = useState<number[]>([]);
  const { id: idString } = useParams<{ id: string }>();
  const id = parseInt(idString!);
  const { pageSize } = useAtomValue(applicationSettingsAtom);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    archived: false,
    type: 'all',
    terms: '',
    page: 1,
    pageSize: pageSize,
  });

  const {
    data: identifierTypes,
  } = useGetIdentifierTypes();

  const {
    refetch,
    isLoading,
    data: invoices,
  } = useQuery({
    queryKey: ['invoices', filterOptions],
    queryFn: () =>
      getInvoices({
        isArchived: filterOptions.archived,
        orderField: 'invoiceDate',
        order: SortOrder.Descending,
        dateRange: {
          from: filterOptions.from?.format('YYYY-MM-DDTHH:mm:ss'),
          to: filterOptions.to?.format('YYYY-MM-DDTHH:mm:ss'),
        },
        type: filterOptions.type === 'all' ? undefined : filterOptions.type,
        page: filterOptions.page,
        pageSize: filterOptions.pageSize,
        searchTerms: filterOptions.terms
          .split(' ')
          .filter((e) => e.trim().length != 0),
        searchFields: [
          'invoiceNumber',
          'amount',
          'supplierInvoiceRelation.name',
          'customerInvoiceRelation.name',
        ], // should match backend entity naming of properties (not dto!)
        types: INVOICE_TYPES[filterOptions.type],
      }),
  });

  const {
    refetch: refetchActiveInvoice,
    isFetching: detailsIsFetching,
    data: activeInvoice,
  } = useQuery({
    queryKey: ['invoice', id],
    enabled: !!id,
    queryFn: () => getInvoiceDetails(id).then(convertToExtendedInvoice),
  });

  const { isFetching: paymentsIsFetching, data: activeInvoicePayments } =
    useQuery({
      queryKey: ['invoice', id, 'payments'],
      enabled: !!id,
      queryFn: () => getInvoicePaymentsByInvoiceId(id),
    });

  const refresh = () => {
    refetch();
    refetchActiveInvoice();
  };

  useEffect(() => {
    if (!invoices) {
      setInvoiceZipFiles([]);
    } else {
      const ids = invoices.data.map((invoice) => invoice.invoiceId);
      setInvoiceZipFiles(ids);
    }
    return () => {
      setInvoiceZipFiles([]);
    };
  }, [invoices]);

  const moduleHandlers: OnSuccessHandlers = {
    onArchiveSuccess() {
      refresh();
    },
    onHandleSuccess() {
      refresh();
    },
    onAutoHandleSuccess() {
      refresh();
    },
    onAutoPaidSuccess() {
      refresh();
    },
    onPaymentSuccess() {
      refresh();
    },
    onDeleteSuccess() {
      refresh();
    },
    onCommentSuccess() {
      refresh();
    },
    onEditSuccess() {
      refresh();
    },
  };

  return (
    <div className={styles.invoiceContainer}>
      <Row>
        <Col span={24} md={12}>
          <DossierFilter
            zipInvoiceIds={invoiceZipFileIds}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
          />
          <div className={styles.dossierContainer}>
            <DossierHeader />
            <DossierList
              isLoading={isLoading}
              invoices={invoices?.data || []}
              pagination={{
                onChange: (page, pageSize) => {
                  setFilterOptions((cv) => ({ ...cv, page, pageSize }));
                },
                page: invoices?.page || 1,
                pageSize: invoices?.pageSize || pageSize,
                total: invoices?.total || 0,
              }}
            />
          </div>
        </Col>
        <Col md={12}>
          <Affix offsetTop={100}>
            <Card
              bodyStyle={{
                height: 'calc(100vh - 200px)',
                overflowY: 'auto',
                padding: '1.5rem',
              }}
              bordered={false}
            >
              <InvoiceDetail
                identifierTypes={identifierTypes || []}
                moduleHandlers={moduleHandlers}
                showPdf
                invoice={activeInvoice}
                invoicePayment={activeInvoicePayments}
                isLoading={isLoading || detailsIsFetching || paymentsIsFetching}
              />
            </Card>
          </Affix>
        </Col>
      </Row>
    </div>
  );
};
