import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Help } from './_tabs/help';
import { Guide } from './_tabs/guide';
import { ReleaseNotes } from './_tabs/releaseNotes';

export const Support = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.settings.support.tabs',
  });
  const items = [
    {
      label: t('guide'),
      key: 'guide',
      children: <Guide key={'guide'} />,
    },
    {
      label: t('help'),
      key: 'help',
      children: <Help key={'help'} />,
    },
    //{
    //  label: t('supportTickets'),
    //  key: 'supportTickets',
    //  children: <SupportTickets key={'supportTickets'} />,
    //},
    {
      label: t('releaseNotes'),
      key: 'releaseNotes',
      children: <ReleaseNotes key={'releaseNotes'} />,
    }
  ];

  return <Tabs items={items} />;
};