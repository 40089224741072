import { Accent, CmsTooltip } from '@/lib/components';
import { debounce } from '@/lib/utils/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, DatePicker, Input, Row, Select, Switch } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOptions } from '../../../lib/types/invoices';
import { DownloadZipButton } from './downloadZip';
import styles from './styles.module.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface DossierFilterProps {
  zipInvoiceIds: number[];
  filterOptions: FilterOptions;
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptions>>;
}

export const DossierFilter: FC<DossierFilterProps> = ({
  filterOptions,
  zipInvoiceIds,
  setFilterOptions,
}) => {
  const { t } = useTranslation();

  const ranges = (): {} => {
    const now = () => dayjs().startOf('day');
    const lastYear = dayjs().subtract(1, 'year');
    return {
      [t('general.calendar.rangePresets.today')]: [now(), now()],
      [t('general.calendar.rangePresets.30days')]: [now().add(-30, 'd'), now()],
      [t('general.calendar.rangePresets.60days')]: [now().add(-60, 'd'), now()],
      [t('general.calendar.rangePresets.90days')]: [now().add(-90, 'd'), now()],
      [t('general.calendar.rangePresets.thisYear')]: [
        now().startOf('year'),
        now().endOf('year'),
      ],
      [t('general.calendar.rangePresets.lastYear')]: [
        lastYear.startOf('year'),
        lastYear.endOf('year'),
      ],
    };
  };

  return (
    <>
      <Row
        gutter={20}
        style={{ margin: 0 }}
        align="bottom"
        className={styles.filterRow}
      >
        <Col md={6}>
          <CmsTooltip toolTipKey="dossier.label.invoiceType">
            <Accent color="primary" type="p">
              {t('dossier.type')}
            </Accent>
          </CmsTooltip>
          <Select
            className={styles.select}
            value={filterOptions.type}
            style={{
              fontSize: '1.4rem',
            }}
            onChange={(type) =>
              setFilterOptions((prev) => ({ ...prev, page: 1, type }))
            }
            bordered={false}
            suffixIcon={
              <FontAwesomeIcon
                icon={['fas', 'angle-down']}
                size="lg"
                className={styles.select}
              />
            }
          >
            <Option value="all">Inkoop/Verkoop</Option>
            <Option value="purchase">Inkoop</Option>
            <Option value="sales">Verkoop</Option>
          </Select>
        </Col>
        <Col style={{ marginLeft: 'auto' }}>
          <DownloadZipButton
            color="secondary"
            size="small"
            invoices={zipInvoiceIds}
          />
        </Col>
      </Row>
      <Row
        gutter={20}
        style={{ margin: 0, marginTop: '1rem' }}
        className={styles.filterRow}
        align="middle"
        justify="space-between"
      >
        <Col span={12}>
          <CmsTooltip toolTipKey="dossier.label.searchTerm">
            <Input
              bordered={false}
              className={styles.search}
              placeholder={t('dossier.searchBarPlaceholder')}
              onChange={debounce(
                (e) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    page: 1,
                    terms: e.target.value,
                  })),
                300,
              )}
            />
          </CmsTooltip>
        </Col>
        <Col>
          <CmsTooltip toolTipKey="dossier.label.archive">
            <Accent type="span" inline>
              {t('dossier.archiveSwitch')}
              <Switch
                style={{ marginLeft: '5px' }}
                size="small"
                checked={filterOptions.archived}
                onChange={(archived) =>
                  setFilterOptions((prev) => ({ ...prev, page: 1, archived }))
                }
                defaultChecked={false}
              />
            </Accent>
          </CmsTooltip>
        </Col>
      </Row>
      <Row
        style={{ margin: '1rem 0' }}
        align="bottom"
        className={styles.filterRow}
      >
        <Col>
          <CmsTooltip toolTipKey="dossier.label.dateRange">
            <RangePicker
              allowEmpty={[true, true]}
              size="middle"
              picker="date"
              format={dayjs.Ls.nl.formats.L}
              ranges={ranges()}
              value={[
                filterOptions?.from
                  ? moment(filterOptions?.from?.toString())
                  : null,
                filterOptions?.to
                  ? moment(filterOptions?.to?.toString())
                  : null,
              ]}
              onChange={(e) => {
                const fromDate = e?.[0]?.toISOString();
                const toDate = e?.[1]?.toISOString();

                const from = fromDate
                  ? dayjs(fromDate).startOf('day')
                  : undefined;
                const to = toDate ? dayjs(toDate).endOf('day') : undefined;
                setFilterOptions((prev) => ({ ...prev, page: 1, from, to }));
              }}
            />
          </CmsTooltip>
        </Col>
      </Row>
    </>
  );
};
