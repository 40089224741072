import { CustomApiError } from '@/lib/types';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import i18n from './i18n';

// @ts-ignore
const API_URL = window.API_URL;

// @ts-ignore
const CMS_GRAPHQL_URL = window.CMS_GRAPHQL_URL;

const DEFAULT_TIMEOUT_MS = 120 * 1000;

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  timeout: DEFAULT_TIMEOUT_MS,
};

const getValidationError = (data: { [key: string]: string[] }): string => {
  if (!data) {
    return '';
  }

  const validationErrors = Object.values(data).map((value) => {
    if (!Array.isArray(value)) {
      return '';
    }

    // Translate te errors
    const errors = value.map((x) => i18n.t(x));
    return errors.join(',');
  });

  return validationErrors.join('\n');
};

const getConflictError = (errorMessage: string) => {
  try {
    const regExp = /\[(.*?)\]/g;
    const matches = Array.from(errorMessage.matchAll(regExp)).map(
      (match: RegExpMatchArray) => match[1],
    );

    return i18n.t('general.errors.conflict', {
      entity: matches[0],
      parameter: '',
      value: matches[1],
    });
  } catch {
    return errorMessage;
  }
};

const errorResponseInterceptor = (
  error: AxiosError,
): Promise<CustomApiError> => {
  const statusCodeParser = {
    400: (data: { [key: string]: string[] }) => getValidationError(data),
    409: (data: string) => getConflictError(data),
    422: (data: string) =>
      i18n.t(`api.${data}`, { ns: 'errors', defaultValue: data }),
    default: (data: any) => i18n.t(data, { ns: 'errors', defaultValue: data }),
  };

  let errorText = i18n.t('Error.DefaultText');

  const statusCode = error.response?.status;
  if (statusCode && error.response?.data) {
    const parser = statusCodeParser[statusCode] || statusCodeParser['default'];
    errorText = parser(error.response.data as any);
  } else {
    errorText = error.message;
  }

  return Promise.reject({ status: error.response?.status, message: errorText });
};

export const api = axios.create(axiosConfig);
api.interceptors.response.use(
  (response) => response,
  (error) => errorResponseInterceptor(error),
);

const cmsAxiosConfig: AxiosRequestConfig = {
  baseURL: CMS_GRAPHQL_URL,
  timeout: DEFAULT_TIMEOUT_MS,
};

export const cmsApi = axios.create(cmsAxiosConfig);
