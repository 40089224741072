import { SingleTaskState, SingleTaskType } from '@/lib/types/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { Col, Popconfirm, Row, Space } from 'antd';

import {
  CustomButton,
  CustomColumnType,
  CustomTable,
  GenericModal,
} from '@/lib/components';
import { SingleTask, SingleTaskModifyRequest } from '@/lib/types/scheduler';
import { showApiNotification } from '@/lib/utils/api-error-handler';
import { showNotification } from '@/lib/utils/showNotification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  cancelSingleTask,
  createSingleTask,
  deleteSingleTask,
  getSingleTasks,
  updateSingleTask,
} from '../../../../lib/adapters/single-tasks-adapter';
import { AddEditTask } from './_components/addEditTask';

export const NotifyUsers = () => {
  const { t } = useTranslation();
  const [isModalVisible, setisModalVisible] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<SingleTask>();
  const [isModifying, setIsModifying] = useState<boolean>(false);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['singletasks'],
    queryFn: () => getSingleTasks(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const tableColumns: CustomColumnType<SingleTask>[] = [
    {
      title: t('settings.page.notifyusers.overview.columns.id'),
      dataIndex: 'id',
    },
    {
      title: t('settings.page.notifyusers.overview.columns.name'),
      dataIndex: 'name',
    },
    {
      title: t('settings.page.notifyusers.overview.columns.description'),
      dataIndex: 'description',
    },
    {
      title: t('settings.page.notifyusers.overview.columns.subject'),
      dataIndex: 'subject',
    },
    {
      title: t('settings.page.notifyusers.overview.columns.type'),
      dataIndex: 'type',
      render: (value) => {
        switch (value) {
          case SingleTaskType.NotifyUsersInApp:
            return t("settings.page.notifyusers.type.inApp");
          case SingleTaskType.NotifyUsersViaEmail:
            return t("settings.page.notifyusers.type.email");
          default:
            return "-"
        }
      }
    },
    {
      title: t('settings.page.notifyusers.overview.columns.executionTimestamp'),
      dataIndex: 'executionTimestamp',
      defaultRender: 'datetimeutc',
      defaultSearch: 'dateonly',
    },
    {
      title: t('settings.page.notifyusers.overview.columns.state'),
      dataIndex: 'state',
      render: (value) => SingleTaskState[value],
    },
    {
      title: t('settings.page.notifyusers.overview.columns.locations'),
      dataIndex: 'locations',
      render: (value, record) => (!value?.length ? 'Alle' : value.length),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'actions',
      enableDefaultSearch: false,
      enableDefaultSorter: false,
      render: (id, task) => (
        <>
          {![SingleTaskState.Success, SingleTaskState.Failed].some(
            (x) => x === task.state,
          ) && (
              <CustomButton
                type="link"
                onClick={() => openModal(task)}
                icon={<FontAwesomeIcon icon="pencil-alt" />}
              />
            )}

          {task.state === SingleTaskState.Queued && (
            <Popconfirm
              title="Weet je het zeker?"
              onConfirm={() => cancelTask(id)}
            >
              <CustomButton
                type="link"
                toolTipKey="settings.page.notifyusers.action.cancel"
                danger
                shape="circle"
                icon={<FontAwesomeIcon icon="ban" />}
              />
            </Popconfirm>
          )}
          <Popconfirm
            title="Weet je het zeker?"
            onConfirm={() => deleteTask(id)}
          >
            <CustomButton
              type="link"
              toolTipKey="settings.page.notifyusers.action.delete"
              danger
              shape="circle"
              icon={<FontAwesomeIcon icon="trash-alt" />}
            />
          </Popconfirm>
        </>
      ),
    },
  ];

  const openModal = (task?: SingleTask) => {
    setSelectedTask(task);
    setisModalVisible(true);
  };

  const onModalClose = () => {
    setSelectedTask(undefined);
    setisModalVisible(false);
  };

  const cancelTask = async (id: number) => {
    setIsModifying(true);
    try {
      await cancelSingleTask(id);
      showNotification('success', `Succesvol geannuleerd`);
      await refetch();
    } catch (e: any) {
      showApiNotification(
        e,
        t(`settings.page.notifyusers.overview.errors.cancel`),
      );
    } finally {
      setIsModifying(false);
    }
  };

  const deleteTask = async (id: number) => {
    setIsModifying(true);
    try {
      await deleteSingleTask(id);
      showNotification('success', `Succesvol verwijderd`);
      await refetch();
    } catch (e: any) {
      showApiNotification(
        e,
        t(`settings.page.notifyusers.overview.errors.delete`),
      );
    } finally {
      setIsModifying(false);
    }
  };

  const normalize = (e: string): string => {
    return e.replaceAll('<p>', `<p style="margin: 0">`);
  };

  const onModalFinish = async (task: SingleTaskModifyRequest) => {
    const isAdd = !selectedTask;
    setIsModifying(true);
    try {
      task.message = normalize(task.message);
      if (!task.locations) {
        task.locations = [];
      }
      if (isAdd) {
        await createSingleTask(task);
      } else {
        await updateSingleTask(selectedTask.id, task);
      }
      onModalClose();
      await refetch();
    } catch (e: any) {
      showApiNotification(
        e,
        t(
          `settings.page.notifyusers.overview.errors.${isAdd ? 'add' : 'update'
          }`,
        ),
      );
    } finally {
      setIsModifying(false);
    }
  };
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify="end">
          <Col>
            <CustomButton
              type="primary"
              toolTipKey="settings.page.notifyusers.action.add"
              onClick={() => openModal()}
            >
              {t('settings.page.notifyusers.overview.actions.add')}
            </CustomButton>
          </Col>
        </Row>
        <CustomTable
          loading={isLoading}
          columns={tableColumns}
          dataSource={data}
        />
      </Space>
      <GenericModal
        width="800px"
        isVisible={isModalVisible}
        hideModal={() => setisModalVisible(false)}
        onCancelHandler={onModalClose}
        footer={false}
        destroyOnClose
        title={
          selectedTask
            ? t('settings.page.notifyusers.overview.actions.update')
            : t('settings.page.notifyusers.overview.actions.add')
        }
      >
        <AddEditTask
          onFinish={onModalFinish}
          onModalClose={onModalClose}
          loading={isLoading || isModifying}
          task={selectedTask}
        ></AddEditTask>
      </GenericModal>
    </>
  );
};
