import { getLocations } from '@/lib/adapters/users-adapter';
import { SingleTask, SingleTaskModifyRequest } from '@/lib/types/scheduler';
import { useQuery } from '@tanstack/react-query';
import { DatePicker, Form, Input, Row, Select, Spin, Switch } from 'antd';
import dayjs from 'dayjs';

import { CustomButton, TextEditor } from '@/lib/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleTaskType } from '@/lib/types';

interface AddEditTaskProps {
  loading: boolean;
  task?: SingleTask;
  onFinish: (task: SingleTaskModifyRequest) => void;
  onModalClose: () => void;
}
export const AddEditTask = ({
  loading,
  task,
  onFinish,
  onModalClose,
}: AddEditTaskProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isLoading, data: locations } = useQuery({
    queryKey: ['locations'],
    queryFn: () => getLocations(),
    retry: false,
  });

  useEffect(() => {
    const updatedTask = {
      ...task,
      executionTimestamp: dayjs.utc(task?.executionTimestamp).local(),
    };
    form.setFieldsValue(updatedTask);
  }, [form, task]);

  return (
    <Spin spinning={isLoading}>
      <Form
        onFinish={(values) => onFinish({ ...task, ...values })}
        form={form}
        labelCol={{ span: 4 }}
      >
        <Form.Item
          name="name"
          label={t('settings.page.notifyusers.overview.columns.name')}
          rules={[
            {
              required: true,
              message: t('settings.page.notifyusers.modify.validations.name'),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="type"
          label={t('settings.page.notifyusers.overview.columns.type')}
        >
          <Select defaultValue={SingleTaskType.NotifyUsersViaEmail} options={
            [
              { label: "Email notificatie", value: SingleTaskType.NotifyUsersViaEmail },
              { label: "MijnAlfa notificatie", value: SingleTaskType.NotifyUsersInApp },
            ]
          } />
        </Form.Item>
        <Form.Item
          name="description"
          label={t('settings.page.notifyusers.overview.columns.description')}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="directExecution"
          label={t(
            'settings.page.notifyusers.overview.columns.directExecution',
          )}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.directExecution !== curValues.directExecution
          }
        >
          {() => {
            const isChecked = form.getFieldValue('directExecution');
            if (isChecked) {
              return;
            }
            return (
              <Form.Item
                name="executionTimestamp"
                label={t(
                  'settings.page.notifyusers.overview.columns.executionTimestamp',
                )}
              >
                <DatePicker
                  showTime
                  format={`${dayjs.Ls.nl.formats.L} ${dayjs.Ls.nl.formats.LTS}`}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item
          name="subject"
          label={t('settings.page.notifyusers.overview.columns.subject')}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="locations"
          label={t('settings.page.notifyusers.overview.columns.locations')}
          help={t('settings.page.notifyusers.modify.help.locations')}
        >
          <Select
            mode="multiple"
            filterOption={(input, option) => {
              if (!option?.children) return false;

              return (
                option?.children
                  ?.toLocaleString()
                  ?.toLowerCase()
                  ?.indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {locations &&
              locations.map((location) => (
                <Select.Option key={location.id} value={location.id}>
                  {location.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="message"
          label={t('settings.page.notifyusers.overview.columns.message')}
          rules={[
            {
              required: true,
              message: t(
                'settings.page.notifyusers.modify.validations.message',
              ),
            },
          ]}
        >
          <TextEditor />
        </Form.Item>

        <Row justify="center">
          <CustomButton loading={loading} type="link" onClick={onModalClose}>
            {t('general.actions.cancel')}
          </CustomButton>
          <CustomButton loading={loading} htmlType="submit" type="primary">
            {t('general.actions.save')}
          </CustomButton>
        </Row>
      </Form>
    </Spin>
  );
};
