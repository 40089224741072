import { setIsInPayment } from '@/lib/adapters/invoice-adapter';
import { createSepaPayment } from '@/lib/adapters/payment-adapter';
import { hasAccessAtom, payableInvoiceCountAtom } from '@/lib/atoms/atoms';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { useRelationContext } from '@/lib/context';
import { showNotification } from '@/lib/utils/showNotification';

import {
  Accent,
  CurrencyDisplay,
  CustomButton,
  DocumentViewModal,
} from '@/lib/components';
import {
  useGetBankAccounts,
  useGetPaymentSettings,
  useGetPayments,
  useGetRenameableRelations,
} from '@/lib/queries';
import { useGetInPaymentInvoices } from '@/lib/queries/invoices/invoices';

import {
  Invoice,
  InvoiceType,
  NettingPaymentEntry,
  PaymentEntry,
} from '@/lib/types';
import {
  PaymentInvoiceItem,
  PaymentProcessStatus,
  PaymentSetting,
} from '@/lib/types/payment';
import { roundToTwoDecimals } from '@/lib/utils/numbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Col,
  Form,
  List,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import dayjs from 'dayjs';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentHeader } from './_components/InPaymentHeader';
import { PaymentInvoice } from './_components/paymentInvoice';
import {
  getNetInvoiceAmount,
  getPaymentInfo,
} from './_helpers/paymentInvoiceHelper';
import styles from './styles.module.scss';
const { Option } = Select;

export const InPayment = () => {
  const { t } = useTranslation();
  const setPayableInvoiceCount = useUpdateAtom(payableInvoiceCountAtom);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { isFrozen } = useRelationContext();

  const [sepaGenerateLoading, setSepaGenerateLoading] = useState(false);
  const [unusedCreditInvoices, setUnusedCreditInvoices] = useState<Invoice[]>(
    [],
  );

  const [form] = Form.useForm();

  const {
    data: invoices,
    refetch: refetchInvoices,
    isLoading: invoicesIsLoading,
  } = useGetInPaymentInvoices();
  const { data: paymentSettings, isLoading: paymentSettingsIsLoading } =
    useGetPaymentSettings();
  const { data: bankAccounts, isLoading: bankAccountsIsLoading } =
    useGetBankAccounts();
  const {
    data: paymentsInProgress,
    isLoading: paymentsIsLoading,
    isFetching: paymentsIsFetching,
    refetch: refetchPayments,
  } = useGetPayments({
    processStatus: PaymentProcessStatus.InProgress,
  });

  const { data: renameableRelationIds, isLoading: renameableIsLoading } =
    useGetRenameableRelations();

  const createPaymentInvoices = (
    invoices: Invoice[],
  ): {
    paymentInvoices: PaymentInvoiceItem[];
    unusedCreditInvoices: Invoice[];
  } => {
    const formValues = form.getFieldsValue(['invoices']);
    const excludedInvoices =
      formValues.invoices?.filter((x) => x.isExcluded) ?? [];

    const isExcluded = (invoiceId: number) => {
      return !!excludedInvoices.find((x) => x.invoice.invoiceId === invoiceId);
    };

    // Netting is determined in the following steps:
    // 1. Check if the invoice has the same counterRelationId
    // 2. Check the amount the invoice
    // If the invoice < creditNota - do not use
    // If the invoice >= creditNota - add it to the invoice
    const purchaseInvoices = invoices
      .filter((x) => x.type.toString() === InvoiceType[InvoiceType.Purchase])
      .sort((a, b) => b.amount - a.amount);

    let creditInvoices = invoices.filter(
      (x) => x.type.toString() === InvoiceType[InvoiceType.CreditNote],
    );

    const newPaymentInvoices: PaymentInvoiceItem[] = [];

    for (let invoice of purchaseInvoices) {
      let restAmount = invoice.amount;

      const nettingInvoices: Invoice[] = [];

      for (let creditInvoice of creditInvoices) {
        if (creditInvoice.counterRelationId !== invoice.counterRelationId) {
          continue;
        }
        const absCreditAmount = Math.abs(creditInvoice.amount);

        if (restAmount >= absCreditAmount) {
          nettingInvoices.push(creditInvoice);
          restAmount -= absCreditAmount;
        }
      }

      // Remove the used creditnote from the list
      creditInvoices = creditInvoices.filter(
        (x) => !nettingInvoices.find((y) => y.invoiceId === x.invoiceId),
      );

      const paymentSetting = getPaymentSettingForInvoice(invoice);
      const paymentInfo = getPaymentInfo(invoice, paymentSetting);

      let netInvoiceAmount = getNetInvoiceAmount(invoice, nettingInvoices);

      // If there is an discount, use it as initialPayment value
      const discountedValue = paymentInfo.discount
        ? roundToTwoDecimals((invoice.amount / 100) * paymentInfo.discount)
        : 0;

      const initialPayment = roundToTwoDecimals(
        netInvoiceAmount - discountedValue,
      );

      const receiverName = invoice.supplier.name;
      const initialPayments = [
        {
          ...paymentInfo,
          value: initialPayment,
        },
      ];
      newPaymentInvoices.push({
        invoice,
        nettingInvoices,
        isExcluded: isExcluded(invoice.invoiceId),
        paymentSetting,
        receiverName,
        paymentTerm: paymentInfo?.paymentTerm,
        discount: paymentInfo?.discount,
        netInvoiceAmount: netInvoiceAmount,
        partialPayments: initialPayments,
      });
    }

    return {
      paymentInvoices: newPaymentInvoices,
      unusedCreditInvoices: creditInvoices,
    };
  };

  const removeCreditInvoice = async (invoice: Invoice) => {
    await setIsInPayment(invoice.invoiceId, !invoice.isInPayment);
    refetchInvoices();
  };

  useEffect(() => {
    if (bankAccounts.length) {
      form.setFieldValue('iban', bankAccounts[0].iban);
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (invoicesIsLoading || paymentSettingsIsLoading) {
      return;
    }

    const { paymentInvoices, unusedCreditInvoices } = createPaymentInvoices(
      invoices.data,
    );

    setPayableInvoiceCount(invoices.data.length);
    setUnusedCreditInvoices(unusedCreditInvoices);
    form.setFieldValue(['invoices'], paymentInvoices);
  }, [invoices, paymentSettings]);

  useEffect(() => {
    if (!paymentsInProgress?.length) {
      refetchInvoices();
    }
  }, [paymentsInProgress]);

  const getBankAccountByIban = (iban: string) =>
    bankAccounts.find((x) => x.iban === iban);

  const createSepa = async (values) => {
    if (paymentsInProgress?.length) {
      showNotification(
        'warning',
        t('api.payment.alreadyInProgress', { ns: 'errors' }),
      );
      return;
    }

    const includedInvoices = values.invoices.filter(
      (x) =>
        !x.isExcluded &&
        (x.partialPayments?.length || x.nettingInvoices?.length),
    );

    try {
      setSepaGenerateLoading(true);
      if (!includedInvoices || !includedInvoices.length) {
        showNotification(
          'warning',
          t('api.payment.noInvoicesSelected', { ns: 'errors' }),
        );
        return;
      }

      const isBatchSelected = values.isBatchSelected ?? false;

      const partialPaymentAmount = Array.isArray(includedInvoices)
        ? includedInvoices.reduce(
          (acc, cv) => (acc += cv.partialPayments.length),
          0,
        )
        : 0;

      if (
        (includedInvoices.length > 75 || partialPaymentAmount > 75)
      ) {
        showNotification(
          'warning',
          t('api.payment.sepaMaxBatchAmount', { ns: 'errors' }),
        );
        return;
      }

      let paymentEntries: PaymentEntry[] = [];
      let nettingPaymentEntries: NettingPaymentEntry[] = [];

      for (let paymentInvoice of includedInvoices) {
        paymentEntries.push(
          ...paymentInvoice.partialPayments.map((payment) => ({
            receiverIban: payment.bankAccount.iban,
            receiverBic: payment.bankAccount.bic,
            receiverName: paymentInvoice.receiverName,
            amount: payment.value,
            discount: paymentInvoice.discount,
            executionDate: new Date(dayjs(payment.date).format('YYYY-MM-DD')),
            invoiceId: paymentInvoice.invoice.invoiceId,
          })),
        );

        nettingPaymentEntries.push(
          ...paymentInvoice.nettingInvoices.map((nettingInvoice) => ({
            invoiceId: nettingInvoice.invoiceId,
            receiverName: paymentInvoice.receiverName,
            nettingInvoiceId: paymentInvoice.invoice.invoiceId,
          })),
        );
      }

      if (!paymentEntries || !paymentEntries.length) {
        throw new Error('Minimaal 1 betaling moet gemaakt worden.');
      }

      const sepa = {
        iban: values.iban,
        isBatchSelected: isBatchSelected,
        paymentEntries,
        nettingPaymentEntries,
      };

      await createSepaPayment(sepa);
      showNotification('info', `SEPA wordt gegenereerd.`);
      await refetchPayments();
      await refetchInvoices();
      resetExcluded();
    } catch (e: any) {
      showNotification(
        'error',
        'Fout tijdens genereren SEPA. Probeer opnieuw.',
      );
    } finally {
      setSepaGenerateLoading(false);
    }
  };

  const resetExcluded = () => {
    const { invoices } = form.getFieldsValue();
    const invoicesClone = [...invoices];

    form.setFieldsValue({
      invoices: invoicesClone.map((x) => ({ ...x, isExcluded: false })),
    });
  };

  const getPaymentSettingForInvoice = (
    invoice: Invoice,
  ): PaymentSetting | undefined => {
    const counterRelationId = invoice.counterRelationId;
    if (!counterRelationId) {
      return;
    }
    return paymentSettings.find(
      (x) => x.counterRelationId === counterRelationId,
    );
  };

  const getTotalValue = () => {
    const fields = form.getFieldsValue(['invoices']);
    const includedInvoices = fields.invoices?.filter((x) => !x.isExcluded);
    if (!includedInvoices || !includedInvoices.length) {
      return 0;
    }

    const total = includedInvoices.reduce((total, currentValue) => {
      if (!currentValue?.partialPayments || !currentValue.partialPayments.length) {
        return total;
      }
      const subTotal = currentValue.partialPayments.reduce(
        (partialTotal, partialCurrent) => partialTotal + (partialCurrent.value ?? 0),
        0,
      );
      return total + subTotal;
    }, 0);
    return total;
  };

  const isLoading =
    invoicesIsLoading ||
    paymentSettingsIsLoading ||
    renameableIsLoading ||
    bankAccountsIsLoading ||
    paymentsIsLoading;

  const sepaInProgress =
    !!paymentsInProgress?.length || sepaGenerateLoading || false;

  return (
    <Spinal initializing={isLoading} loading={paymentsIsFetching}>
      <Row>
        <Col flex={1}>
          <h1>{t('payment.title')}</h1>
        </Col>
        {unusedCreditInvoices.length > 0 && (
          <Col>
            <Popover
              title={t('payment.unusedCreditInvoice')}
              content={
                <List
                  dataSource={unusedCreditInvoices}
                  split={false}
                  bordered={false}
                  size="small"
                  renderItem={(invoice) => (
                    <List.Item>
                      {invoice.invoiceNumber}
                      <DocumentViewModal
                        id={invoice.sourceIdentifier}
                        idType="processfile"
                      />
                      <CustomButton
                        type="text"
                        shape="circle"
                        toolTipKey="payment.action.deleteInvoice"
                        onClick={() => removeCreditInvoice(invoice)}
                        icon={<FontAwesomeIcon color="red" icon="trash-alt" />}
                      />
                    </List.Item>
                  )}
                />
              }
            >
              <CustomButton
                color="tertiary"
                shape="circle"
                toolTipKey="payment.action.deleteInvoice"
                icon={<FontAwesomeIcon icon="exclamation-triangle" />}
              />
            </Popover>
          </Col>
        )}
      </Row>

      {sepaInProgress ? (
        <Alert
          message={
            <Space size="small">
              <span>
                {t('api.payment.alreadyInProgress', { ns: 'errors' })}
              </span>
              <CustomButton
                size="small"
                type="primary"
                ghost
                onClick={() => refetchPayments()}
              >
                <FontAwesomeIcon icon="redo" rotation={270} />
                {t('payment.action.refresh')}
              </CustomButton>
            </Space>
          }
          type="info"
        />
      ) : null}

      <Form form={form} onFinish={createSepa} layout="vertical">
        <Row>
          <Form.Item
            label={
              <Accent color="tertiary">{t('label.chooseBankAccount')}</Accent>
            }
            name="iban"
            rules={[{ required: true }]}
            initialValue={bankAccounts?.[0]?.iban}
          >
            <Select
              style={{
                width: '220px',
                fontSize: '1rem',
              }}
              placeholder="Selecteer iban"
              bordered={false}
              disabled={sepaInProgress}
              suffixIcon={
                <FontAwesomeIcon
                  icon={['fas', 'angle-down']}
                  size="lg"
                  className={styles.select}
                />
              }
            >
              {bankAccounts?.map((bankAccount) => (
                <Option key={bankAccount.id} value={bankAccount.iban}>
                  {bankAccount.iban}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
        <PaymentHeader />
        <div className={styles.rowContainer}>
          <Form.List name="invoices">
            {(fields, { add, remove }, { errors }) => {
              const onHandleRemove = async (invoice: Invoice, index) => {
                try {
                  remove(index);
                  await setIsInPayment(invoice.invoiceId, !invoice.isInPayment);
                  await refetchInvoices();
                  form.resetFields();
                } catch (e) {
                  showNotification('error', 'Verwijderen mislukt');
                }
              };

              const onHandleRemoveCreditInvoice = async (
                invoice: Invoice,
                index,
              ) => {
                try {
                  // Remove it from the fields
                  const { invoices } = form.getFieldsValue();
                  const invoicesClone = [...invoices];

                  invoicesClone[index].nettingInvoices = invoicesClone[
                    index
                  ].nettingInvoices.filter(
                    (x) => x.invoiceId !== invoice.invoiceId,
                  );
                  form.setFieldsValue({ invoices: invoicesClone });

                  await removeCreditInvoice(invoice);
                } catch {
                  showNotification('error', 'Verwijderen mislukt');
                }
              };

              const onHandleExclude = (exclude: boolean, index: number) => {
                try {
                  // Remove it from the fields
                  const { invoices } = form.getFieldsValue();
                  const invoicesClone = [...invoices];

                  invoicesClone[index].isExcluded = exclude;
                  form.setFieldsValue({ invoices: invoicesClone });
                } catch {
                  showNotification('error', 'Aanpassen mislukt');
                }
              };

              return (
                <>
                  {fields.map((field, index) => {
                    const paymentInvoice: PaymentInvoiceItem =
                      form.getFieldValue(['invoices', field.name]);
                    return (
                      <div className={styles.invoiceRow} key={index}>
                        <PaymentInvoice
                          paymentInvoice={paymentInvoice}
                          isRenameable={
                            renameableRelationIds.includes(
                              paymentInvoice.invoice.supplier.relationId,
                            ) || false
                          }
                          isDisabled={
                            hasAccess.isAccountManager ||
                            isFrozen ||
                            sepaInProgress
                          }
                          invoiceIndex={index}
                          form={form}
                          path={[field.name]}
                          removeInvoice={() =>
                            onHandleRemove(paymentInvoice.invoice, index)
                          }
                          removeCreditInvoice={(invoice) =>
                            onHandleRemoveCreditInvoice(invoice, index)
                          }
                          handleExclude={(exclude: boolean) =>
                            onHandleExclude(exclude, index)
                          }
                        />
                      </div>
                    );
                  })}
                </>
              );
            }}
          </Form.List>
        </div>
        <Row gutter={10} style={{ marginTop: '20px' }} justify="end">
          <Col style={{ textAlign: 'right' }}>
            <Form.Item shouldUpdate>
              {() => (
                <>
                  <span>{t('payment.totalValue')}: </span>
                  <CurrencyDisplay amount={getTotalValue()} />
                </>
              )}
            </Form.Item>
          </Col>
          <Col>
            <Popconfirm
              title={t('payment.sepaConfirm')}
              onConfirm={() => form.submit()}
              placement="topRight"
            >
              <CustomButton
                icon={
                  <FontAwesomeIcon icon={['far', 'credit-card']} size="sm" />
                }
                shape="round"
                type="primary"
                loading={sepaGenerateLoading}
                toolTipKey="payment.action.createSepa"
                disabled={
                  hasAccess.isAccountManager || isFrozen || sepaInProgress
                }
              >
                {t('payment.sepa')}
              </CustomButton>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </Spinal>
  );
};