import {
  deleteNotification,
  getNotifications,
  markAsSeenBasedOnFilter,
  patchNotification,
} from '@/lib/adapters/notification-adapter';
import { hasAccessAtom } from '@/lib/atoms/atoms';
import { Accent, CustomButton } from '@/lib/components';
import { useRelationContext } from '@/lib/context';
import { Notification, NotificationLevel, PaginationParams } from '@/lib/types';
import { convertEnumToArray } from '@/lib/utils/helpers';
import { showNotification } from '@/lib/utils/showNotification';
import { Row, Select, Tabs } from 'antd';
import { useAtomValue } from 'jotai/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollList } from '../scroll-list/scrollList';
import TrackingItem from './trackingItem';

interface TrackingProps {
  onChange?: () => void;
}

export const Tracking: React.FunctionComponent<TrackingProps> = ({
  onChange,
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<'all' | 'unread'>('unread');
  const [activeLevel, setActiveLevel] = useState<number>();
  const [pagination, setPagination] = useState<PaginationParams>();
  const [items, setItems] = useState<Notification[]>([]);
  const hasAccess = useAtomValue(hasAccessAtom);
  const { relation, isFrozen } = useRelationContext();

  useEffect(() => {
    loadInitial();
  }, [mode, activeLevel]);

  const loadInitial = async () => {
    let res = await load(1, mode, activeLevel);
    if (!res) return;

    setPagination(res);
    setItems(res.data);
  };

  const handleSeenAll = async () => {
    try {
      await markAsSeenBasedOnFilter({
        category: 'InvoiceStatus',
        level: activeLevel,
      });
      onChange?.();
    } catch (e) {
      console.error(e);
      return;
    }

    // edit current items
    if (mode === 'all') {
      // mark as read
      const markedItems = items.map((item) =>
        item.isMarkedAsSeen ? item : { ...item, isMarkedAsSeen: true },
      );

      setItems(markedItems);
    } else {
      // clear all items, since they are marked as read now
      setItems([]);
    }
  };

  const handleSeen = async (id) => {
    try {
      await patchNotification(id, true);
      onChange?.();
    } catch (e) {
      console.error(e);
      return;
    }

    // update item list
    if (mode === 'all') {
      // mark item as read in list
      const updated = [...items].map((item) =>
        item.id === id ? { ...item, isMarkedAsSeen: true } : item,
      );
      setItems(updated);
    } else {
      // remove item from list
      setItems(items.filter((item) => item.id !== id));
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteNotification(id);
      onChange?.();
    } catch (e) {
      showNotification('error', 'Fout tijdens verwijderen notificatie.');
      return;
    }

    // Update the list
    setItems(items.filter((item) => item.id !== id));
  };

  const loadMore = async (page) => {
    let res = await load(page, mode, activeLevel);
    if (!res) return;

    setPagination(res);
    setItems([...items, ...res.data]);
  };

  const load = async (
    page: number,
    mode: string,
    level: number | undefined,
  ) => {
    try {
      return await getNotifications({
        includeSeen: mode === 'all',
        page: page,
        category: 'InvoiceStatus',
        pageSize: 20,
        level: level,
        onlyParent: true,
      });
    } catch (e) {
      console.error(e);
      showNotification('error', 'Could not get notifications'); //todo translate
      return null;
    }
  };

  const TabAction = () => {
    const enumList = convertEnumToArray(NotificationLevel);
    return (
      <Select
        onChange={setActiveLevel}
        onClear={() => setActiveLevel(undefined)}
        placeholder="Kies een niveau"
        allowClear
        style={{ width: 200 }}
        value={activeLevel}
      >
        {enumList.map((level) => (
          <Select.Option key={level.key} value={level.key}>
            {level.value}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const notificationList = () => {
    return (
      <ScrollList loadMore={loadMore} pagination={pagination} height="70vh">
        <>
          {items.map((notification) => (
            <TrackingItem
              key={notification.id}
              mode={mode}
              notification={notification}
              onDelete={handleDelete}
              onSeen={handleSeen}
            />
          ))}
        </>
      </ScrollList>
    );
  };

  const onTabChange = (activeKey: string) => {
    setMode(activeKey as any);
  };

  return (
    <div className="container">
      <Row justify="space-between">
        <Accent type="h2">Tracking</Accent>
        <CustomButton
          onClick={handleSeenAll}
          type="link"
          disabled={
            !(hasAccess.isCustomer || (hasAccess.canImpersonate && relation)) ||
            isFrozen
          }
          color="primary"
          shape="round"
          toolTipKey="notifications.action.markAllAsRead"
        >
          {t('notifications.clearBtn')}
        </CustomButton>
      </Row>
      <Tabs
        tabBarExtraContent={{ right: <TabAction /> }}
        onChange={onTabChange}
        items={[
          {
            label: 'Ongelezen',
            key: 'unread',
            children: notificationList(),
          },
          {
            label: 'Alles',
            key: 'all',
            children: notificationList(),
          },
        ]}
      />
    </div>
  );
};
