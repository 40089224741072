import { getInvoices } from '@/lib/adapters/invoice-adapter';
import { getRelations } from '@/lib/adapters/users-adapter';
import {
  CustomColumnType,
  CustomTable,
  DocumentViewModal,
} from '@/lib/components';
import { DeleteButton } from '@/lib/components/invoice-buttons/deleteButton';
import { ResendButton } from '@/lib/components/invoice-buttons/resendButton';
import { SwitchChannelButton } from '@/lib/components/invoice-buttons/switchChannelButton';
import { ScopeTrackingButton } from '@/lib/components/scope';
import { InvoiceFilter, InvoiceWithOwner, SortOrder } from '@/lib/types';
import { IdentifierCategory } from '@/lib/types/enums';
import { getOwnerFilterBase } from '@/lib/utils/dynamic-table-filter';
import { showNotification } from '@/lib/utils/showNotification';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const baseColumnSettings: CustomColumnType<InvoiceWithOwner> = {
  enableDefaultSorter: false,
  sorter: () => 0,
  onFilter: (_, __) => true,
  filterMultiple: false,
};

export const InvoiceOverview = () => {
  const { t } = useTranslation();

  const [tableSettings, setTableSettings] = useState<InvoiceFilter>({
    page: 1,
    pageSize: 10,
    orderField: 'invoiceDate',
    order: SortOrder.Descending,
    exactMatch: false,
  });

  const [invoices, setInvoices] = useState<InvoiceWithOwner[]>();
  const [total, setTotal] = useState(0);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (tableSettings) => {
      setLoading(true);

      try {
        const invoices = await getInvoices(tableSettings);
        setTotal(invoices.total);

        const relationIds = Array.from(
          new Set(invoices.data.map((x) => x.ownerRelationId)),
        );

        const relations = relationIds.length
          ? (
              await getRelations({
                relationIds: relationIds,
              })
            )?.data
          : [];

        const invoicesWithOwners: InvoiceWithOwner[] = invoices.data.map(
          (invoice) => ({
            ...invoice,
            owner: relations.find(
              (relation) => relation.id === invoice.ownerRelationId,
            ),
          }),
        );

        setInvoices(invoicesWithOwners);
      } catch {
        showNotification('error', 'Fout tijdens ophalen facturen');
      } finally {
        setLoading(false);
      }
    };

    fetchData(tableSettings);
  }, [tableSettings]);

  const onTableChange = async (pagination, filters, sorter) => {
    setLoading(true);
    const filter = await getOwnerFilterBase(
      pagination,
      filters,
      sorter,
      tableSettings.pageSize,
      'invoiceDate',
    );
    setTableSettings((current) => ({ ...current, ...filter }));
    setLoading(false);
  };

  const tableColumns: CustomColumnType<InvoiceWithOwner>[] = [
    {
      ...baseColumnSettings,
      title: t('renders.invoice.owner.customerNumber'),
      dataIndex: 'ownerCustomerNumber',
      render: (_, row: InvoiceWithOwner) => {
        return row.owner?.identifiers?.find(
          (identifier) => identifier.category === IdentifierCategory.CUSTOMER,
        )?.identifier;
      },
      sorter: false,
    },
    {
      ...baseColumnSettings,
      title: t('renders.invoice.owner.name'),
      dataIndex: 'ownerName',
      render: (_, row: InvoiceWithOwner) => {
        return row.owner?.name;
      },
      sorter: false,
    },
    {
      title: t('renders.invoice.customer'),
      dataIndex: 'customerInvoiceRelation.name',
      render: (_, row: InvoiceWithOwner) => {
        return row.customer?.name;
      },
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.details.invoiceNumber'),
      dataIndex: 'invoiceNumber',
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.details.invoiceDate'),
      dataIndex: 'invoiceDate',
      defaultRender: 'dateonly',
      ...baseColumnSettings,
      defaultSearch: 'dateonly',
    },
    {
      title: t('renders.invoice.supplier'),
      dataIndex: 'supplierInvoiceRelation.name',
      render: (_, row: InvoiceWithOwner) => {
        return row.supplier?.name;
      },
      ...baseColumnSettings,
    },
    {
      title: t('renders.invoice.invoiceAmount'),
      dataIndex: 'amount',
      defaultRender: 'currency',
      ...baseColumnSettings,
    },
    {
      width: 200,
      dataIndex: 'invoiceId',
      render: (id, row: InvoiceWithOwner) => (
        <>
          <ScopeTrackingButton scopeId={row.scopeId} />
          <DocumentViewModal id={id} idType="invoice" />
          <ResendButton
            invoiceId={id}
            onSuccess={() =>
              setTableSettings((current) => ({ ...current, page: 1 }))
            }
          />

          <SwitchChannelButton
            invoiceId={id}
            onSuccess={() =>
              setTableSettings((current) => ({ ...current, page: 1 }))
            }
          />
          <DeleteButton
            invoiceId={id}
            onSuccess={() =>
              setTableSettings((current) => ({ ...current, page: 1 }))
            }
          />
        </>
      ),
    },
  ];

  return (
    <CustomTable
      rowKey="id"
      style={{ marginTop: '2rem' }}
      loading={isLoading}
      columns={tableColumns}
      dataSource={invoices}
      onChange={onTableChange}
      pagination={{
        current: tableSettings.page,
        pageSize: tableSettings.pageSize,
        hideOnSinglePage: true,
        total: total,
        onChange: () => {},
      }}
      size="small"
    />
  );
};
