import { Accent, CustomButton } from '@/lib/components';
import { IdentifierCategory, RelationType } from '@/lib/types/enums';
import { Relation } from '@/lib/types/user';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomStepProps } from './types';

export const Summary: FC<CustomStepProps> = ({
  afasRelation,
  lyantheRelation,
  extraData,
  identifier,
  setCurrentPage,
  submitCreate,
}) => {
  const { t } = useTranslation('management', {
    keyPrefix: 'relations.addRelation.steps.summary',
  });

  const afasIdentifiers =
    afasRelation?.identifiers.map((relationIdentifier) => {
      const type =
        relationIdentifier.category === IdentifierCategory.COC
          ? 'NL:KVK'
          : 'ALFA:CUSTOMERNUMBER';

      return {
        category: relationIdentifier.category,
        identifier: relationIdentifier.identifier,
        identifierType: type,
      };
    }) || [];
  let identifiers = [...afasIdentifiers];
  if (lyantheRelation) {
    if (lyantheRelation?.vatNumber) {
      identifiers = [
        ...identifiers,
        {
          category: IdentifierCategory.VAT,
          identifier: lyantheRelation.vatNumber,
          identifierType: 'NL:VAT',
        },
      ];
    }
    if (lyantheRelation?.thirdPartyIdentifier) {
      identifiers = [
        ...identifiers,
        {
          category: IdentifierCategory.CUSTOMER,
          identifier: lyantheRelation.thirdPartyIdentifier,
          identifierType: 'LYANTHE:CUSTOMERNUMBER',
        },
      ];
    }
  }

  const relation: Relation = {
    id: '',
    name: afasRelation!.name,
    street: afasRelation?.street,
    houseNumber: afasRelation?.houseNumber,
    zipcode: afasRelation?.zipcode,
    city: afasRelation?.city,
    country: afasRelation?.country,
    locationId: extraData?.locationId,
    mailbox: extraData?.mailbox,
    isFrozen: false,
    safeEmailAddresses: extraData?.safeEmailAddresses,
    theme: extraData?.theme,
    identifiers: identifiers,
    type: RelationType.Customer,
    blockBulkNotifications: false,
    isBlocked: false,
  };
  return (
    <>
      <Accent type="h2" color="secondary">
        {t('title')}
      </Accent>
      <Row gutter={16}>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.name')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.name}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.address')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.street} {relation?.houseNumber}, {relation?.city}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.zipcode')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.zipcode}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.location')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {extraData?.locationName}
        </Col>
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.theme')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.theme}
        </Col>
        {/* Safe EmailAdress */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.mailbox')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.mailbox}
        </Col>
        {/* Safe EmailAdress */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.safeMailAddress')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation?.safeEmailAddresses?.map((safeMailAddress, index) => (
            <>
              <div key={index}>{safeMailAddress}</div>
            </>
          ))}
        </Col>

        {/* IDENTIFIERS */}
        <Col span={12} className="text-right">
          <Accent color="primary" type="strong">
            {t('labels.identifiers')}
          </Accent>
        </Col>
        <Col span={12} className="text-left">
          {relation.identifiers.map((relationIdentifier, index) => {
            const typeText =
              identifier?.toString() === relationIdentifier.identifier
                ? 'KVK'
                : relationIdentifier.category == IdentifierCategory.VAT
                ? 'BTWNummer'
                : 'Klantnummer';
            return (
              <div key={index}>
                {relationIdentifier.identifier} - {typeText}
              </div>
            );
          })}
        </Col>
        <Col span={24} className="text-center">
          <div style={{ marginTop: '2rem' }}>
            <CustomButton
              type="link"
              onClick={() => setCurrentPage?.((prev) => prev - 1)}
            >
              {t('actions.back')}
            </CustomButton>
            <CustomButton
              type="primary"
              onClick={() => submitCreate?.(relation)}
            >
              {t('actions.submit')}
            </CustomButton>
          </div>
        </Col>
      </Row>
    </>
  );
};
